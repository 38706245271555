/* global cns */
import { module as M } from 'modujs';

class LoadJob extends M {
	constructor(m) {
		super(m);

		this.events = {
			click: 'load'
		};
	}

	load() {
		// console.log('🖨️ LoadJob.load');

		this.fetch()
			.then(response => response.json())
			.then(html => this.insert(html))
			.finally(() => this.update());
	}

	fetch() {
		// console.log('❤️ LoadJob.fetch', this.getData('id'));

		const url = new URL(cns.ajax_url);

		const params = {
			action: 'load_job',
			nonce: cns.nonce,
			id: this.getData('id'),
		};

		Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

		this.locked();

		return fetch(url);
	}

	insert({ html }) {
		// console.log('➡️ LoadJob.insert', html);

		if (!html) {
			return;
		}

		this.call('insert', { html }, 'LoadJobContainer', 'main');
	}

	update() {
		this.unlocked();
	}

	locked() {
		this.call('locked', false, 'LoadJobContainer', 'main');
	}

	unlocked() {
		this.call('unlocked', false, 'LoadJobContainer', 'main');
	}

	destroy() {
		// console.log('🗑 LoadJob.destroy');

		this.el.removeEventListener('click', this.load);
	}
}

export default LoadJob;
