import { module as M } from 'modujs';
import Switch from '@19h47/switch';
import { html } from 'utils/environment';

class ThemeSwitcher extends M {
	init() {
		this.switch = new Switch(this.el);
		this.switch.init();

		if ('dark' === localStorage.getItem('cns-theme')) {
			this.activate();
		}

		this.switch.on('Switch.activate', () => {
			window.localStorage.setItem('cns-theme', 'dark');
			html.setAttribute('data-theme', 'dark');
		});

		this.switch.on('Switch.deactivate', () => {
			window.localStorage.setItem('cns-theme', 'light');
			html.setAttribute('data-theme', 'light');
		});
	}

	activate() {
		this.switch.activate();
	}

	deactivate() {
		this.switch.deactivate();
	}
}

export default ThemeSwitcher;
