/* global wpcf7 */
import { module as M } from 'modujs';

class Form extends M {

	initWPCF7() {
		const $form = this.el.querySelector('form');

		wpcf7.init($form);

	}
}

export default Form;
