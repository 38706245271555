import { module as M } from 'modujs';

/**
 * Vimeo
 *
 * @constructor
 * @param {object} container
 */
class Video extends M {
	constructor(m) {
		super(m);

		this.events = {
			click: {
				button: 'play',
			},
		};

		this.play = this.play.bind(this);
		this.stop = this.stop.bind(this);
	}

	init() {
		this.$('video')[0].addEventListener('play', this.play);
		this.$('video')[0].addEventListener('ended', this.stop);
	}

	stop() {
		this.call('stop', null, 'Video');
		this.el.classList.remove('is-play');
		this.$('video')[0].pause();

		this.$('overlay')[0].style.removeProperty('opacity');
		this.$('overlay')[0].style.removeProperty('visibility');

		this.$('button')[0].style.removeProperty('opacity');
		this.$('button')[0].style.removeProperty('visibility');
	}

	play() {
		this.el.classList.add('is-active');
		this.el.classList.add('is-play');
		this.$('video')[0].play();

		this.$('overlay')[0].style.setProperty('opacity', '0');
		this.$('overlay')[0].style.setProperty('visibility', 'hidden');

		this.$('button')[0].style.setProperty('opacity', '0');
		this.$('button')[0].style.setProperty('visibility', 'hidden');
	}
}

export default Video;
