import { module as M } from 'modujs';

class LoadJobContainer extends M {
	locked() {
		this.el.classList.add('is-processing');
	}

	unlocked() {
		this.el.classList.remove('is-processing');
	}

	insert({ html }) {
		// console.log('➡️ LoadJobContainer.insert', html);

		this.el.innerHTML = html;

		this.call('destroy', this.el, 'app');
		this.call('update', this.el, 'app');
	}

	remove() {
		this.el.innerHTML = '';
	}
}

export default LoadJobContainer;
