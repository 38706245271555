import { module as M } from 'modujs';

class BlockSolutions extends M {
	constructor(m) {
		super(m);

		this.events = {
			click: {
				button: 'toggle',
			},
		};

		this.index = 0;
	}

	toggle({ currentTarget }) {
		this.index = JSON.parse(this.getData('index', currentTarget));
		const $currentItem = this.$('item')[this.index];

		if (0 === this.index && $currentItem.classList.contains('is-active')) {
			return false;
		}

		if ($currentItem.classList.contains('is-active')) {
			return this.close();
		}

		return this.open();
	}

	open() {
		const previous = 0 > this.index - 1 ? 0 : this.index - 1;

		this.$('item').forEach($item => {
			$item.classList.remove('is-open');
			$item.classList.remove('is-active');
		});

		this.$('item')[previous].classList.add('is-open');

		this.$('item')[this.index].classList.add('is-open');
		this.$('item')[this.index].classList.add('is-active');
	}

	close() {
		const previous = 0 > this.index - 1 ? 0 : this.index - 1;

		for (let i = this.$('item').length - 1; i >= this.index; i -= 1) {
			this.$('item')[i].classList.remove('is-open');
			this.$('item')[i].classList.remove('is-active');
		}

		this.$('item')[previous].classList.add('is-open');
		this.$('item')[previous].classList.add('is-active');
	}
}

export default BlockSolutions;
