const APP_NAME = 'CNS';
const DATA_API_KEY = '.data-api';

const html = document.documentElement;
const { body } = document;
const isDebug = html.hasAttribute('data-debug');
const production = 'production' === process.env.NODE_ENV;

const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

const scroll = {
	left: 0,
	top: 0,
};

export { APP_NAME, DATA_API_KEY, html, body, isDebug, production, scroll, vw, vh };
