import { module as M } from 'modujs';

class BlockCities extends M {
	constructor(m) {
		super(m);

		this.events = {
			click: {
				button: 'toggle',
			},
		};
	}

	init() {
		const query = new URLSearchParams(window.location.search);

		if (query.get('city')) {
			const currentId = query.get('city');

			this.open(currentId);
		}
	}

	toggle({ currentTarget }) {
		const currentId = this.getData('id', currentTarget);

		this.open(currentId);
	}

	open(currentId) {
		this.$('item').forEach($item => {
			const id = this.getData('id', $item);

			if (id === currentId) {
				$item.classList.add('is-active');
				$item.style.setProperty('display', 'block');
			} else {
				$item.classList.remove('is-active');
				$item.style.setProperty('display', 'none');
			}
		});

		this.$('button').forEach($button => {
			const id = this.getData('id', $button);

			if (id === currentId) {
				$button.classList.add('is-active');
			} else {
				$button.classList.remove('is-active');
			}
		});

		this.call('scrollTo', [this.$('items')[0]], 'Scroll', 'main');
		// this.call('update', {}, 'Scroll', 'main');
	}
}

export default BlockCities;
