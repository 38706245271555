import { module as M } from 'modujs';

import { disableScroll, enableScroll } from 'utils/scroll';

class Drawer extends M {
	constructor(m) {
		super(m);

		this.isOpen = this.el.classList.contains('is-active') || false;
		this.keydown = this.keydown.bind(this)

		this.events = {
			click: {
				close: 'close',
				backdrop: 'close',
			},
		};

		// On esc key
		document.addEventListener('keydown', this.keydown);
	}

	// init() { // eslint-disable-line class-methods-use-this
	// console.log('🚀 Drawer.init');
	// }

	keydown({ key }) {
		if ('Escape' === key && this.isOpen) {
			this.close();
		}
	}

	toggle() {
		// console.info('🔘 Drawer.toggle', this.isOpen);

		if (this.isOpen) return this.close();

		return this.open();
	}

	open() {
		if (this.isOpen) return false;

		// console.log('✅ Drawer.open', this.isOpen);

		this.isOpen = true;

		this.el.classList.add('is-animatable');
		this.el.classList.add('is-active');

		// When drawer is open, disableScroll
		disableScroll();

		this.call('stop', false, 'Scroll', 'main');

		return true;
	}

	close() {
		if (!this.isOpen) return false;

		// console.log('❌ Drawer.close', this.isOpen);

		this.isOpen = false;

		this.el.classList.remove('is-active');

		// When drawer is closed, enableScroll
		enableScroll();
		this.call('start', false, 'Scroll', 'main');

		setTimeout(() => {
			this.el.classList.remove('is-animatable');
			this.$('body')[0].scrollTo(0, 0);
			this.call('remove', null, 'LoadJobContainer');
		}, 500);

		return true;
	}

	destroy() {
		// console.log('🗑 Drawer.destroy');
		document.removeEventListener('keydown', this.keydown);
	}
}

export default Drawer;
