import { module as M } from 'modujs';

class DialogButton extends M {
	constructor(m) {
		super(m);

		this.events = {
			click: 'toggle'
		}
	}

	init() {
		this.id = this.getData('id') || null;
		this.method = this.getData('method') || 'open';
	}

	toggle() {
		console.log(this.id);
		this.call('close', false, 'Dialog');
		this.call(this.method, null, 'Dialog', this.id);
	}
}

export default DialogButton;
