import { module as M } from 'modujs';
import A from '@19h47/accordion';

class Accordion extends M {
	init() {
		this.accordion = new A(this.el);
		this.accordion.init();

		this.accordion.panels.forEach(panel => {
			panel.rootElement.addEventListener('Panel.open', () => this.update());
			panel.rootElement.addEventListener('Panel.close', () => this.update());
		});

		this.call('update', {}, 'Scroll', 'main');
	}

	update() {
		setTimeout(() => {
			this.call('update', {}, 'Scroll', 'main');
		}, 500);
	}

	destroy() {
		this.accordion.panels.forEach(panel => {
			panel.rootElement.removeEventListener('Panel.open', () => this.update());
			panel.rootElement.removeEventListener('Panel.close', () => this.update());
		});
	}
}
export default Accordion;
