/* global cns */
import { module as M } from 'modujs';

class FormFilter extends M {
	constructor(m) {
		super(m);

		this.events = {
			submit: 'submit',
		};
	}

	submit(event) {
		event.preventDefault();

		const formData = new FormData(event.target);
		const params = new URLSearchParams(formData).toString();;

		if (params) {
			this.call('goTo', { href: `${cns.jobs_url}?${params}` }, 'Load');
		}
	}
}

export default FormFilter;
