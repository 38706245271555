/* global cns */
import Modular from 'modujs';
import Guid from 'vendors/Guid';
import * as modules from 'modules';
import { html, production } from 'utils/environment';

if (false === production) {
	const guid = new Guid(16);

	guid.init();
}

const app = new Modular({ modules });

function init() {
	app.init(app);

	html.classList.add('is-first-loaded');
	html.classList.add('is-loaded');
	html.classList.add('is-ready');
	html.classList.remove('is-loading');
}

window.onload = () => {
	const $style = document.getElementById(`${cns.text_domain}-main-css`);

	if (window.isMobile) {
		html.classList.add('is-mobile');
	}

	html.setAttribute('data-theme', localStorage.getItem(`${cns.text_domain}-theme`) || 'light');

	if ($style.isLoaded) {
		init();
	} else {
		$style.addEventListener('load', () => init());
	}
};
