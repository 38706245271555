import { module as M } from 'modujs';

class DrawerButton extends M {
	constructor(m) {
		super(m);

		this.events = {
			click: 'toggle',
		};
	}

	init() {
		// console.log('🚀 DrawerButton.init');

		this.method = this.getData('method') || 'toggle';
	}

	toggle(event) {
		event.preventDefault();

		// console.log('🔘 DrawerButton.toggle', { method: this.method, id: this.getData('id'), modules: this.modules });

		this.call(this.method, null, 'Drawer', this.getData('id'));
	}

	// destroy() { // eslint-disable-line class-methods-use-this
	// 	console.log('🗑 DrawerButton.destroy');
	// }
}

export default DrawerButton;
