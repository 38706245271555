import { module as M } from 'modujs';
import Swiper, { EffectFade } from 'swiper';
import gsap from 'gsap';

Swiper.use(EffectFade);

const animation = (el, direction = 1) => {
	const timeline = gsap.timeline({ paused: true });

	timeline.set(el, { x: 0 });
	timeline.to(el, { duration: 0.25, x: `${100 * direction}%` });
	timeline.set(el, { x: `${-100 * direction}%` });
	timeline.to(el, { duration: 0.25, x: 0 });

	return timeline;
};

class Carousel extends M {
	constructor(m) {
		super(m);

		this.events = {
			click: {
				previous: 'previous',
				next: 'next',
				to: 'to',
			},
			mouseenter: {
				previous: 'mouseenter',
				next: 'mouseenter',
			},
			// mouseleave: {
			// 	previous: 'mouseleave',
			// 	next: 'mouseleave',
			// },
		};
	}

	// mouseleave({ currentTarget }) {
	// 	animation(currentTarget.querySelector('span'), parseInt(this.getData('direction', currentTarget), 10))
	// 		.reverse()
	// 		.play();
	// }

	mouseenter({ currentTarget }) {
		animation(
			currentTarget.querySelector('span'),
			parseInt(this.getData('direction', currentTarget), 10),
		).play();
	}

	init() {
		// console.log('🚀 Carousel.init');

		this.swiper = new Swiper(this.$('slideshow')[0], {
			loop: this.getData('loop'),
			modules: [EffectFade],
			slidesPerView: 'auto',
			autoHeight: JSON.parse(this.getData('auto-height')),
			// loopedSlides: 1,
			speed: JSON.parse(this.getData('speed')) || 1000,
			// observeParents: true,
			// observeSlideChildren: true,
			// virtualTranslate: true,
			effect: this.getData('effect') || 'slide',
			fadeEffect: {
				crossFade: true,
			},
			// breakpoints: {
			// 	700: {
			// 		slidesPerView: 2,
			// 	},
			// },
			on: {
				init: () => {
					setTimeout(() => {
						this.call('update', {}, 'Scroll', 'main');
					}, 1000);
				},

				slideChange: ({ realIndex }) => {
					if (this.$('to').length) {
						this.$('to').forEach($to => $to.classList.remove('is-active'));
						this.$('to')[realIndex].classList.add('is-active');
					}
				},
			},
		});
	}

	previous() {
		this.swiper.slidePrev();
	}

	next() {
		this.swiper.slideNext();
	}

	to({ currentTarget }) {
		this.$('to').forEach($to => $to.classList.remove('is-active'));

		currentTarget.classList.add('is-active');
		this.swiper.slideTo(this.getData('to', currentTarget));
	}

	// destroy() {
	// console.log('🗑 Carousel.destroy');

	// this.swiper.destroy();
	// }
}

export default Carousel;
