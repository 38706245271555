import { module as M } from 'modujs';
import { html } from 'utils/environment';
import { Native } from 'locomotive-scroll';

const isOntop = () => {
	const top = window.pageYOffset || document.documentElement.scrollTop;

	if (0 === top) {
		html.classList.add('is-ontop');
		html.setAttribute('data-direction', 'up');

	} else {
		html.classList.remove('is-ontop');
	}
};

class Scroll extends M {
	init() {
		// console.log('🚀 Scroll.init')

		this.scroll = new Native({
			el: this.el,
			getDirection: true,
			resetNativeScroll: true,
		});

		this.direction = 'down';

		this.scroll.on('scroll', ({ direction }) => {
			html.setAttribute('data-direction', direction);
			this.direction = direction;
			isOntop();
		});

		isOntop();

		this.scroll.on('call', (func, way, obj) => {
			if (obj.id) {
				// console.log(func[0], { way, obj, direction: this.direction }, func[1], obj.id);
				this.call(func[0], { way, obj, direction: this.direction }, func[1], obj.id);
			}
		});
	}

	toggleLazy(args) {
		const src = this.getData('lazy', args.obj.el);

		if (src.length) {
			if ('IMG' === args.obj.el.tagName) {
				args.obj.el.src = src;
			} else {
				args.obj.el.style.backgroundImage = `url(${src})`;
			}
			this.setData('lazy', '', args.obj.el);
		}
	}

	scrollTo(param) {
		if (this.scroll && this.scroll.scrollTo) {
			this.scroll.scrollTo(param[0], { offset: param[1] });
		}
	}

	stop() {
		return this.scroll.stop();
	}

	start() {
		return this.scroll.start();
	}

	update() {
		// console.log('🥯 Scroll.update');

		this.scroll.update();
	}

	destroy() {
		html.removeAttribute('data-direction');
		this.scroll.destroy();
	}
}

export default Scroll;
