/* global cns */
import { module as M } from 'modujs';

class BlockCInside extends M {
	constructor(m) {
		super(m);

		this.events = {
			submit: {
				form: 'submit',
			},
		};
	}

	submit(event) {
		event.preventDefault();

		const formData = new FormData(this.$('form')[0]);
		const action = formData.get('action');
		const profile = formData.get('profile');

		console.log({ profile, action });

		if ('go_to_c_inside' === action) {
			this.call('goTo', { href: `${cns.c_inside_url}?profile=${profile}` }, 'Load');
		}

		if ('go_to_home' === action) {
			this.call('goTo', { href: cns.interview_url }, 'Load');
		}

		if ('go_to_jobs' === action) {
			this.call('goTo', { href: `${cns.jobs_url}?profile=${profile}` }, 'Load');
		}

		if ('open_chat' === action) {
			const a = document.createElement('a');

			a.target = '_blank';
			a.href = cns.c_inside_chat;
			a.click();
		}
	}
}

export default BlockCInside;
