import { module as M } from 'modujs';
import { Box, Button } from '@19h47/listbox';

class Listbox extends M {
	init() {
		const $listbox = this.$('listbox')[0];
		const $button = this.$('button')[0];

		// Init box.
		const box = new Box($listbox, false, true);
		box.init();

		// Init button.
		const button = new Button($button, box);
		button.init();

		if (this.$('input')[0]) {
			$button.addEventListener('Button.change', ({ detail }) => {
				this.$('input')[0].value = this.getData('id', detail.item);
			});
		}
	}
}
export default Listbox;
