import { module as M } from 'modujs';
import ModulardLoad from 'modularload';
import { gsap } from 'gsap';
import { html } from 'utils/environment';

class Load extends M {
	init() {
		this.load = new ModulardLoad({
			enterDelay: 300,
			exitDelay: 300,
		});

		// eslint-disable-next-line no-unused-vars
		this.load.on('loading', (transition, oldContainer) => {
			this.call('close', null, 'Nav');
			gsap.to(oldContainer, { duration: 0.3, opacity: 0 });
		});

		this.load.on('loaded', (transition, oldContainer, newContainer) => {
			this.call('destroy', oldContainer, 'app');
			this.call('update', newContainer, 'app');
			this.call('initWPCF7', null, 'Form');

			html.setAttribute('data-direction', '');

			// console.log('🖨️ Load.loaded', this.modules);

			gsap.set(newContainer, { opacity: 0 });
		});

		this.load.on('ready', (transition, newContainer) => {
			gsap.to(newContainer, {
				duration: 0.1,
				opacity: 1,
			});
		});

		this.load.on('images', () => this.call('update', null, 'Scroll', 'main'));
	}

	goTo({ href, transition, isUrl }) {
		this.load.goTo(href, transition, isUrl);
	}
}

export default Load;
