import { module as M } from 'modujs';

class UrlSwitcher extends M {
	constructor(m) {
		super(m);


		this.events = {
			change: 'change',
			'Button.change': 'buttonChange'
		}
	}

	buttonChange({ detail }) {
		this.call('goTo', { href: `?profile=${detail.item.getAttribute('data-listbox-id')}` }, 'Load');
	}

	change({ currentTarget }) {
		this.call('goTo', { href: `?profile=${currentTarget.value}` }, 'Load');
	}
}

export default UrlSwitcher;
