import { module as M } from 'modujs';
import gsap from 'gsap';

gsap.registerEffect({
	name: 'counter',
	extendTimeline: true,
	defaults: {
		end: 0,
		duration: 0.5,
		ease: 'power1',
		increment: 1,
	},
	effect: (targets, { duration, end, increment, ease }) => {
		const tl = gsap.timeline();
		const num = targets[0].innerText.replace(/,/g, '');
		targets[0].innerText = num;

		tl.to(
			targets,
			{
				duration,
				innerText: end,
				modifiers: {
					innerText: innerText =>
						gsap.utils
							.snap(increment, innerText)
							.toString()
					// .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
				},
				ease,
			},
			0,
		);

		return tl;
	},
});

class CounterInView extends M {
	constructor(m) {
		super(m)

		const end = this.getData('end');

		this.timeline = gsap.timeline({ paused: true });
		this.timeline.counter(this.el, { duration: 1, end, increment: 0.01 });
	}

	play() {
		this.timeline.play();
	}
}

export default CounterInView;
