import { module as M } from 'modujs';

class ContentJob extends M {
	init() {
		// console.log('🚀 ContentJob.init');

		this.scroll = this.scroll.bind(this);

		this.$('body')[0].addEventListener('scroll', this.scroll);
	}

	scroll({ currentTarget }) {
		if (0 === currentTarget.scrollTop) {
			return this.el.classList.add('is-ontop');
		}

		return this.el.classList.remove('is-ontop');
	}

	destroy() {
		// console.log('🗑 ContentJob.destroy');

		this.$('body')[0].removeEventListener('scroll', this.scroll);
	}
}

export default ContentJob;
