import { module as M } from 'modujs';

const resizeSelect = $select => {
	const tempOption = document.createElement('option');
	tempOption.textContent = $select.selectedOptions[0].textContent;

	const tempSelect = document.createElement('select');
	tempSelect.style.setProperty('visibility', 'hidden');
	tempSelect.style.setProperty('position', 'fixed');
	tempSelect.appendChild(tempOption);

	$select.after(tempSelect);
	$select.style.setProperty('width', `${tempSelect.getBoundingClientRect().width + 4}px`);

	tempSelect.remove();
};

class BlockCInside extends M {
	constructor(m) {
		super(m);

		this.events = {
			change: 'resize'
		}

		this.resize = this.resize.bind(this);
	}

	init() {
		resizeSelect(this.el);

		window.addEventListener('resize', this.resize);
	}

	resize() {
		resizeSelect(this.el);
	}

	destroy() {
		window.removeEventListener('resize', this.resize);
	}
}

export default BlockCInside;
