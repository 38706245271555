import { module as M } from 'modujs';
import Player from '@vimeo/player';

/**
 * Vimeo
 *
 * @constructor
 * @param {object} container
 */
class Vimeo extends M {
	constructor(m) {
		super(m);

		this.player = null;

		this.events = {
			click: {
				play: 'play',
			},
		};

		this.play = this.play.bind(this);
		this.stop = this.stop.bind(this);
	}

	init() {
		this.videoId = this.getData('id', this.$('player')[0]);

		this.player = new Player(this.$('player')[0], {
			id: this.videoId,
			width: 640,
		});

		this.initEvents();
	}

	initEvents() {
		this.player.on('play', this.play);
		this.player.on('ended', this.stop);
		this.player.on('pause', this.stop);
	}

	stop() {
		this.el.classList.remove('is-play');
		this.player.pause();
		this.$('poster')[0].style.removeProperty('opacity');
		this.$('poster')[0].style.removeProperty('visibility');
		this.$('overlay')[0].style.removeProperty('opacity');
		this.$('overlay')[0].style.removeProperty('visibility');
	}

	play() {
		this.el.classList.add('is-active');
		this.el.classList.add('is-play');
		this.player.play();
		this.$('poster')[0].style.setProperty('opacity', '0');
		this.$('poster')[0].style.setProperty('visibility', 'hidden');
		this.$('overlay')[0].style.setProperty('opacity', '0');
		this.$('overlay')[0].style.setProperty('visibility', 'hidden');
	}
}

export default Vimeo;
