import { module as M } from 'modujs';

class NavButton extends M {
	constructor(m) {
		super(m);

		this.events = {
			click: 'toggle'
		}
	}

	toggle() {
		// console.log('NavButton.toggle');

		this.call('toggle', null, 'Nav');
	}
}

export default NavButton;
